import React from "react"
import { asCurrency } from "../../services/Utils"
import { PurchaseOrderItemService } from "../../services/PurchaseOrderItemService"
import { QuantityInput } from "../catalog/QuantityInput"
import { QuantitySelect } from "../seller/QuantitySelect"
import classNames from "classnames"
import { debounce } from "lodash"

export class PurchaseOrderItemEdit extends React.Component {
  constructor(props) {
    super(props)

    this.onChangeQuantity = this.onChangeQuantity.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.changePurchaseOrderItem = this.changePurchaseOrderItem.bind(this)
    this.save = debounce(this.save, 1000)

    this.state = {
      purchase_order_item: props.purchase_order_item,
      status: 'waiting'
    }
  }

  onDelete() {
    const purchase_order_item = this.props.purchase_order_item
    PurchaseOrderItemService.destroy(purchase_order_item.id).then(
      () => { this.props.deletedPurchaseOrderItem(purchase_order_item) },
      (error) => {
        this.setState({ status: 'failed' })
        toast(error.message, { className: "bg-danger text-white" })
      }
    )
  }

  onChangeQuantity(newQuantity) {
    let purchase_order_item = this.state.purchase_order_item
    purchase_order_item.quantity = newQuantity
    this.setState({ purchase_order_item: purchase_order_item, status: 'sending' }, this.save)
  }

  save() {
    const purchase_order_item = this.state.purchase_order_item
    PurchaseOrderItemService.update(purchase_order_item).then((new_purchase_order_item) => {
      this.setState({ purchase_order_item: new_purchase_order_item, status: 'success' },
        () => { setTimeout(() => { this.setState({status: 'waiting'}) }, 1000) }
      )
      this.props.updatedPurchaseOrderItem(new_purchase_order_item)
      },
      (error) => {
        this.setState({ status: 'failed' })
        toast(error.message, { className: "bg-danger text-white" })
      }
    )
  }

  toggleAdvanced() {
    this.setState({ showAdvanced: !this.state.showAdvanced })
  }

  changePurchaseOrderItem(event) {
    let purchase_order_item = this.state.purchase_order_item
    purchase_order_item[event.target.name] = event.target.value
    this.setState({ purchase_order_item: purchase_order_item, status: 'sending' }, this.save)
  }

  renderDeleteButton() {
    return (
      <button
        onClick={ this.onDelete }
        className="btn btn-outline-danger btn-sm h-100">
        <span className="fa fa-times"></span>
      </button>
    )
  }

  renderCatchWeightDescription() {
    const purchase_order_item = this.state.purchase_order_item
    if (!purchase_order_item.actual_unit_price) { return }
    const catch_weight = purchase_order_item.actual_quantity
    const unit = purchase_order_item.product_unit.catch_weight_unit
    const item_unit = (unit == 'pound' ? 'lbs' : unit)
    const denominatorUnit = (unit == 'pound' ? 'lb' : unit)
    const rate = asCurrency(purchase_order_item.actual_unit_price)

    const warningClass = classNames({
      "text-danger": !purchase_order_item.actual_quantity
    })

    return (
      <div>
        <small className={warningClass}>
          { `Actual: ${catch_weight || 'TBD'} ${item_unit} × ${rate} / ${denominatorUnit}` }
        </small>
      </div>
    )
  }

  renderNote() {
    if (!this.state.purchase_order_item.note_from_seller || this.state.showAdvanced) { return }
    return (
      <div>
        <span className="fa fa-sticky-note mr-2"></span>
        <em>{ this.state.purchase_order_item.note_from_seller }</em>
      </div>
    )
  }

  renderDepositDescription() {
    if ( this.state.purchase_order_item.deposit_average == 0 ) { return }

    let message
    if ( this.state.purchase_order_item.deposit_average > 0 ) {
      message = 'This item has a refundable deposit'
    } else {
      message = 'Refundable deposit'
    }

    return (
      <div className='text-secondary small'>
        <span className='fa fa-recycle mr-1'></span>
        { message }
      </div>
    )
  }

  renderDeposit() {
    if ( this.state.purchase_order_item.deposit_average == 0 ) { return }
    return (
      <div className='small'>
        <span className='fa fa-recycle mr-1'></span>
        { asCurrency(this.state.purchase_order_item.deposit_average) }
      </div>
    )
  }

  renderDepositSubtotal() {
    if ( this.state.purchase_order_item.deposit_subtotal == 0 ) { return }
    return (
      <div className='small'>
        <span className='fa fa-recycle mr-1'></span>
        { asCurrency(this.state.purchase_order_item.deposit_subtotal) }
      </div>
    )
  }

  renderSubtotal() {
    const purchase_order_item = this.state.purchase_order_item
    return (
      <div className='text-right'>
        <span>{ asCurrency(purchase_order_item.subtotal) }</span>
      </div>
    )
  }

  renderQuantity() {
    const purchase_order_item = this.props.purchase_order_item
    return (
      <div>
        <QuantitySelect
          value={ purchase_order_item.quantity }
          minimum={ purchase_order_item.product_unit.quantity_minimum  }
          initial={ purchase_order_item.product_unit.quantity_minimum }
          onChange={ this.onChangeQuantity }
          />
      </div>
    )
  }

  renderCatchWeightInput() {
    if (!this.props.showCatchWeight) { return }
    if (!this.props.purchase_order_item.actual_unit_price) { return }
    return (
      <div className="mt-2">
        <input
          type="text"
          name="actual_quantity"
          placeholder="Catch weight"
          value={ this.state.purchase_order_item.actual_quantity || "" }
          onChange={ this.changePurchaseOrderItem }
          className="form-control text-right sig-3"
        />
      </div>
    )
  }

  renderUnavailable() {
    const product_unit = this.props.purchase_order_item.product_unit
    if (product_unit.is_published) { return }

    if (product_unit.unavailable_until_description) {
      return (
        <div>
          <span className='text-warning fa fa-clock mr-1'></span>
          { product_unit.unavailable_until_description }
        </div>
      )
    } else {
      return (
        <div className="text-danger">
          <span className='text-danger fa fa-exclamation-triangle mr-1'></span>
          This product is not currently available
        </div>
      )
    }
  }

  render() {
    const purchase_order_item = this.props.purchase_order_item
    const product_unit = purchase_order_item.product_unit

    const status = this.state.status
    const rowClass = classNames({
      "order-row": true,
      "py-2": true,
      "d-flex": true,
      "alert-dark": !purchase_order_item.product_unit.is_published,
      "save-failed": status === "failed",
      "save-pending": status === "sending",
      "save-successful": status === "success"
    })

    let minimum
    if (product_unit.quantity_minimum > 1) {
      minimum = <span className="badge border ml-2">{`${product_unit.quantity_minimum} minimum`}</span>
    }

    return (
      <div className={rowClass}>
        <div className="toggle-more-column">
          { this.renderDeleteButton() }
        </div>
        <div className="quantity-column">
          <div className="w-100">
            <div>
              { this.renderQuantity() }
            </div>
            { this.renderCatchWeightInput() }
          </div>
        </div>
        <div className="item-column d-flex">
          <div className='pr-2'>
            <img src={product_unit.product_photo.small_url} height="60" />
          </div>
          <div>
            <div className="small text-muted">{ product_unit.producer_name }</div>
            <div className="font-weight-bold">{ product_unit.product_name }</div>
            <div>{ product_unit.unit } {minimum}</div>
            { this.renderCatchWeightDescription() }
            { this.renderNote() }
            { this.renderDepositDescription() }
            { this.renderUnavailable() }
          </div>
        </div>
        <div>
          <div className='d-flex no-gutters'>
            <div className='unit-price-column'>
              { asCurrency(purchase_order_item.effective_unit_price) }
              { this.renderDeposit() }
            </div>
            <div className='subtotal-price-column'>
              { this.renderSubtotal() }
              { this.renderDepositSubtotal() }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default PurchaseOrderItemEdit