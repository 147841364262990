import React from "react"
import { asCurrency, formatDate } from "../../services/Utils"
import { ChangeOrderService } from "../../services/ChangeOrderService"


import { OrderInfo } from "./OrderInfo"
import { Forecast } from "./Forecast"
import { SalesOrderItemService } from "../../services/SalesOrderItemService"
import { QuantitySelect } from "./QuantitySelect"
import classNames from "classnames"
import { debounce } from "lodash"
import { UncontrolledTooltip } from "reactstrap"
import { toast } from "react-toastify"

export class SalesOrderItemEdit extends React.Component {
  constructor(props) {
    super(props)

    this.onChangeQuantity = this.onChangeQuantity.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.changeOrder = this.changeOrder.bind(this)
    this.onMoveRadioChanged = this.onMoveRadioChanged.bind(this)

    this.toggleAdvanced = this.toggleAdvanced.bind(this)
    this.changeSalesOrderItem = this.changeSalesOrderItem.bind(this)
    this.changePriceOverride = this.changePriceOverride.bind(this)
    this.changeSubtotalOverride = this.changeSubtotalOverride.bind(this)

    this.copyInternalNote = this.copyInternalNote.bind(this)
    this.save = debounce(this.save, 1000)

    this.state = {
      moveRadio: 'backorder-inventory',
      moveType: 'backorder',
      sales_order_item: props.sales_order_item,
      status: 'waiting'
    }
  }

  changeOrder(event) {
    let sales_order_item = this.state.sales_order_item
    const existingOrderId = Number(event.currentTarget.name)
    const notes = sales_order_item.note_from_seller
    const moveType = this.state.moveType
    const reason = this.state.reason
    if (moveType == 'backorder') {
      let errorMessages = []
      if ( sales_order_item.quantity == 0 )
        errorMessages.push('the line item requires a quantity')
      if (!notes)
        errorMessages.push('add a note from seller')
      if ( errorMessages.length != 0 ) {
        const message = errorMessages.join(', ')
        this.setState({ errorMessage: `To backorder please ${ message }`})
        return
      }
      sales_order_item.quantity = 0
    }
    this.props.changeOrder(sales_order_item.id, moveType, existingOrderId, notes, reason)
    this.setState({ sales_order_item: sales_order_item, showAdvanced: false})
  }

  copyInternalNote(event) {
    let sales_order_item = this.state.sales_order_item
    sales_order_item.internal_seller_note = event.target.value
    this.setState({sales_order_item: sales_order_item}, this.save)
  }

  onMoveRadioChanged(event) {
    const newMoveRadio = event.target.value
    let newStateValues = { moveRadio: newMoveRadio }
    if (newMoveRadio == 'move' ) {
      newStateValues.moveType = 'move'
      newStateValues.reason = undefined
    } else if (newMoveRadio == 'backorder-inventory' ) {
      newStateValues.moveType = 'backorder'
      newStateValues.reason = 'inventory'
    } else if (newMoveRadio == 'backorder-deadline' ) {
      newStateValues.moveType = 'backorder'
      newStateValues.reason = 'deadline'
    } else if (newMoveRadio == 'backorder-other' ) {
      newStateValues.moveType = 'backorder'
      newStateValues.reason = 'deadline'
    }
    this.setState(newStateValues)
  }

  onDelete() {
    const sales_order_item = this.props.sales_order_item

    return SalesOrderItemService.destroy(sales_order_item.id).then(
      (new_sales_order_item) => { this.props.deletedSalesOrderItem(sales_order_item.id) },
      (error) => {
        this.setState({ status: 'failed' })
        toast(error.message, { className: "bg-danger text-white" })
      }
    )
  }

  onChangeQuantity(newQuantity) {
    let sales_order_item = this.state.sales_order_item
    if (newQuantity) { sales_order_item.quantity = newQuantity }
    const nextStatus = newQuantity == '' ? 'waiting' :  'sending'
    sales_order_item.quantity = newQuantity
    this.setState({ sales_order_item: sales_order_item, status: 'sending' }, this.save)
  }

  save() {
    let sales_order_item = this.state.sales_order_item
    return SalesOrderItemService.update(sales_order_item).then((new_sales_order_item) => {
      sales_order_item.calculated_subtotal = new_sales_order_item.calculated_subtotal
      sales_order_item.effective_unit_price = new_sales_order_item.effective_unit_price
      sales_order_item.deposit_average = new_sales_order_item.deposit_average
      sales_order_item.deposit_subtotal = new_sales_order_item.deposit_subtotal
      sales_order_item.subtotal = new_sales_order_item.subtotal

      this.setState({ sales_order_item: sales_order_item, status: 'waiting' })
      this.props.updatedSalesOrderItem(new_sales_order_item)
      },
      (error) => {
        this.setState({ status: 'failed' })
        toast(error.message, { className: "bg-danger text-white" })
      }
    )
  }

  toggleAdvanced() {
    this.setState({ showAdvanced: !this.state.showAdvanced })
  }

  changePriceOverride(event) {
    let sales_order_item = this.state.sales_order_item
    sales_order_item[event.target.name] = event.target.value
    sales_order_item['subtotal_override'] = null
    this.setState({ sales_order_item: sales_order_item, status: 'sending' }, this.save)
  }

  changeSubtotalOverride(event) {
    let sales_order_item = this.state.sales_order_item
    sales_order_item[event.target.name] = event.target.value
    sales_order_item['price_override'] = null
    this.setState({ sales_order_item: sales_order_item, status: 'sending' }, this.save)
  }

  changeSalesOrderItem(event) {
    let sales_order_item = this.state.sales_order_item
    sales_order_item[event.target.name] = event.target.value
    this.setState({ sales_order_item: sales_order_item, status: 'sending' }, this.save)
  }

  renderDeleteButton() {
    if (!this.state.showAdvanced) { return }
    return (
      <div className="mt-4">
        <button
          onClick={ this.onDelete }
          className="btn btn-outline-danger btn-sm">
          <span className="fa fa-fw fa-trash"></span>
        </button>
      </div>
    )
  }

  renderSticky(lineId, iconLetter, tooltipText) {
    const id = `tooltip-${ lineId }-${ iconLetter }`
    return (
      <div className="mr-2">
        <span id={ id } className="fa-stack">
          <span className="far fa-sticky-note fa-lg fa-stack-1x"></span>
          <span className="fa fa-stack-1x small">{ iconLetter }</span>
        </span>
        <UncontrolledTooltip placement="bottom" target={ id } >
          { tooltipText }
        </UncontrolledTooltip>
      </div>
    )
  }

  renderNoteInput() {
    let internalNote
    if (!this.props.isPurchaseOrder) {
      internalNote = (
        <div className="form-row mb-2 justify-content-start">
          { this.renderSticky(this.state.sales_order_item.id, '', 'Internal Note')}
          <div className="flex-fill">
            <div className='input-group'>
              { this.renderReasonDropdown() }
              <input
                type="text"
                name="internal_seller_note"
                placeholder='Add an internal note...'
                value={ this.state.sales_order_item.internal_seller_note || ""}
                onChange={ this.changeSalesOrderItem }
                className="form-control"
              />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="mt-2">
        <div className="form-row mb-1 justify-content-start">
          { this.renderSticky(this.state.sales_order_item.id, 'S', 'Note from seller') }
          <div className="flex-fill">
            <input
              type="text"
              name="note_from_seller"
              placeholder='Add a note from the seller...'
              value={ this.state.sales_order_item.note_from_seller || "" }
              onChange={ this.changeSalesOrderItem }
              className="form-control w-100"
            />
          </div>
        </div>
        <div className="form-row mb-2 justify-content-start">
          { this.renderSticky(this.state.sales_order_item.id, 'B', 'Note from buyer')}
          <div className="flex-fill">
            <input
              type="text"
              name="note_from_buyer"
              placeholder='Add a note from the buyer...'
              value={ this.state.sales_order_item.note_from_buyer || ""}
              onChange={ this.changeSalesOrderItem }
              className="form-control w-100"
            />
          </div>
        </div>
        { internalNote }
      </div>
    )
  }

  renderMove() {
    const open_orders = this.props.open_orders
    if ( open_orders == undefined ) { return }
    const sales_order_item = this.state.sales_order_item

    const draftOrderButtons = open_orders.map((open_order) => {
      return (
        <span key={open_order.id} className='mr-1'>
          <button name={open_order.id} className="btn btn-sm btn-outline-secondary" onClick={this.changeOrder}>
            <OrderInfo order={open_order}/>
          </button>
        </span>
      )
    })

    return (
      <div className="mt-4">
        <div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" name='move-radio' type="radio" onChange={this.onMoveRadioChanged} id={ `backorder-inventory-${sales_order_item.id}` } value="backorder-inventory" checked={this.state.moveRadio == 'backorder-inventory'}  />
            <label className="form-check-label" htmlFor={ `backorder-inventory-${sales_order_item.id}` } >Backorder (Inventory)</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" name='move-radio' type="radio" onChange={this.onMoveRadioChanged} id={ `backorder-deadline-${sales_order_item.id}` } value="backorder-deadline" checked={this.state.moveRadio == 'backorder-deadline'}  />
            <label className="form-check-label" htmlFor={ `backorder-deadline-${sales_order_item.id}` } >Backorder (Deadline)</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" name='move-radio' type="radio" onChange={this.onMoveRadioChanged} id={ `backorder-other-${sales_order_item.id}` } value="backorder-other" checked={this.state.moveRadio == 'backorder-other'}  />
            <label className="form-check-label" htmlFor={ `backorder-other-${sales_order_item.id}` } >Backorder (Other)</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" name='move-radio' type="radio" onChange={this.onMoveRadioChanged} id={ `move-${sales_order_item.id}` } value="move" checked={this.state.moveRadio == 'move' } />
            <label className="form-check-label" htmlFor={ `move-${sales_order_item.id}` }>Move</label>
          </div>
          <div>
            <span className='text-danger'>
              { this.state.errorMessage }
            </span>
          </div>
        </div>
        <button className="btn btn-sm btn-outline-secondary mr-1" onClick={this.changeOrder}>
          <span className='fa fa-plus mr-1'></span>
          New Sales Order
        </button>
        { draftOrderButtons }
      </div>
    )
  }

  renderCatchWeightDescription() {
    const sales_order_item = this.state.sales_order_item
    if (!sales_order_item.actual_unit_price) { return }
    const catch_weight = sales_order_item.actual_quantity
    const unit = sales_order_item.product_unit.catch_weight_unit
    const item_unit = (unit == 'pound' ? 'lbs' : unit)
    const denominatorUnit = (unit == 'pound' ? 'lb' : unit)
    const rate = asCurrency(sales_order_item.actual_unit_price)

    const warningClass = classNames({
      "text-danger": !sales_order_item.actual_quantity
    })

    return (
      <div>
        <small className={warningClass}>
          { `Actual: ${catch_weight || 'TBD'} ${item_unit} × ${rate} / ${denominatorUnit}` }
        </small>
      </div>
    )
  }

  renderAdvancedEdit() {
    if (!this.state.showAdvanced) { return }
    return (
      <div>
        { this.renderNoteInput() }
        { this.renderMove() }
      </div>
    )
  }

  renderSellerNote() {
    if (!this.state.sales_order_item.note_from_seller || this.state.showAdvanced) { return }
    return (
      <div className="d-flex align-items-baseline">
        { this.renderSticky(this.state.sales_order_item.id, 'S', 'Note from seller')}
        <em>{ this.state.sales_order_item.note_from_seller }</em>
      </div>
    )
  }

 renderReasonDropdown() {

   if ( !this.props.commonInternalNotes ) { return }

   const reasonButtons = this.props.commonInternalNotes.map((reason) => {
     return (
       <button key={reason} value={reason} className="dropdown-item" onClick={this.copyInternalNote}>
         { reason }
       </button>
     )
   })

   return (
     <div className="input-group-prepend">
       <div className="dropdown dropright">
         <a className="btn btn-outline-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         </a>
         <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
           { reasonButtons }
         </div>
       </div>
     </div>
   )
 }

  renderBuyerNote() {
    if (!this.state.sales_order_item.note_from_buyer || this.state.showAdvanced) { return }
    return (
      <div className="d-flex align-items-baseline">
        { this.renderSticky(this.state.sales_order_item.id, 'B', 'Note from buyer')}
        <em>{ this.state.sales_order_item.note_from_buyer }</em>
      </div>
    )
  }

  renderInternalSellerNote() {
    const sales_order_item = this.state.sales_order_item
    if (this.props.isPurchaseOrder || !sales_order_item.internal_seller_note || this.state.showAdvanced) { return }
    return (
      <div className="d-flex align-items-baseline">
        { this.renderSticky(this.state.sales_order_item.id, '', 'Internal Seller Note')}
        <em>{ sales_order_item.internal_seller_note }</em>
      </div>
    )
  }

  renderSubtotalOverride() {
    if (!this.state.showAdvanced) { return }
    const subtotal_override = this.state.sales_order_item.subtotal_override || ''
    const price_override = this.state.sales_order_item.price_override || ''
    return (
      <div className='text-right mt-2 d-flex justify-content-end'>
        <div>
          <div>
            <label className='mb-1 small'>OVERRIDE</label>
            <input
              type="text"
              name="price_override"
              value={ price_override }
              onChange={ this.changePriceOverride }
              style={{ maxWidth: '90px'}}
              className="form-control text-right px-1" />
          </div>
        </div>
        <div>
          <div>
            <label className='mb-1 small'>OVERRIDE</label>
            <input
              type="text"
              name="subtotal_override"
              value={ subtotal_override }
              onChange={ this.changeSubtotalOverride }
              style={{ maxWidth: '90px'}}
              className="form-control text-right px-1" />
          </div>
        </div>
      </div>
    )
  }

  renderDepositOverride() {
    if (!this.state.showAdvanced) { return }
    const deposit_override = this.state.sales_order_item.deposit_override || ''
    return (
      <div className='text-right mt-2 d-flex justify-content-end'>
        <div>
          <div className="input-group">
            <div className='input-group-prepend'>
              <div className='input-group-text px-1'>
                <span className='fa fa-recycle'></span>
              </div>
            </div>
            <input
              type="text"
              name="deposit_override"
              value={ deposit_override }
              placeholder="0.00"
              onChange={ this.changeSalesOrderItem }
              style={{ maxWidth: '80px'}}
              className="form-control text-right" />
          </div>
        </div>
      </div>
    )
  }

  renderSubtotal() {
    const sales_order_item = this.state.sales_order_item
    let icon
    if ( sales_order_item.subtotal_override) {
      icon = <span className="fa fa-exclamation-triangle mr-2"></span>
    }
    return (
      <div className='text-right'>
        <span>{ icon }{ asCurrency(sales_order_item.subtotal) }</span>
      </div>
    )
  }

  renderUnitPrice() {
    const sales_order_item = this.state.sales_order_item
    let icon
    if (sales_order_item.price_override) {
      icon = <span className="fa fa-exclamation-triangle mr-2"></span>
    }
    return (
      <div className='text-right'>
        <span>{ icon }{ asCurrency(sales_order_item.effective_unit_price) }</span>
      </div>
    )
  }

  renderOriginalEffectivePrice() {
    const sales_order_item = this.state.sales_order_item
    if ( !sales_order_item.original_effective_price ) { return }

    return (
      <div className='small text-muted'>
        <s>{ asCurrency(sales_order_item.original_effective_price) }</s>
      </div>
    )
  }

  renderDeposit() {
    const sales_order_item = this.state.sales_order_item
    if ( sales_order_item.deposit_average == 0 ) { return }

    return (
      <div className='small'>
        <span className='fa fa-recycle mr-1'></span>
        { asCurrency(sales_order_item.deposit_average) }
      </div>
    )
  }

  renderDepositSubtotal() {
    const sales_order_item = this.state.sales_order_item
    if ( Number(sales_order_item.deposit_subtotal) == 0 && !sales_order_item.deposit_override) { return }
    const overridden = !!sales_order_item.deposit_override
    let icon
    if (overridden) {
      icon = <span className="fa fa-exclamation-triangle mr-2"></span>
    }
    return (
      <div className='small'>
        { icon }
        <span className='fa fa-recycle mr-1'></span>
        { asCurrency(sales_order_item.deposit_subtotal) }
      </div>
    )
  }

  renderOriginalSubtotal() {
    const sales_order_item = this.state.sales_order_item
    if ( !sales_order_item.original_subtotal ) { return }

    return (
      <div className='small text-muted'>
        <s>{ asCurrency(sales_order_item.original_subtotal) }</s>
      </div>
    )
  }

  renderCatchWeightInput() {
    if (!this.state.showAdvanced) { return }
    if (!this.state.sales_order_item.actual_unit_price) { return }
    return (
      <div className="mt-2">
        <input
          type="text"
          name="actual_quantity"
          placeholder="Catch weight"
          value={ this.state.sales_order_item.actual_quantity || "" }
          onChange={ this.changeSalesOrderItem }
          className="form-control text-center px-0" />
      </div>
    )
  }

  renderQuantity() {
    const sales_order_item = this.state.sales_order_item
    return (
      <div>
        <QuantitySelect
          value={ sales_order_item.quantity }
          allowDecimal={ true }
          onChange={ this.onChangeQuantity }
          onlyNegativeQuantity={ this.props.onlyNegativeQuantity }
          minimum={ sales_order_item.product_unit.quantity_minimum }
          />
        { this.renderCatchWeightInput() }
      </div>
    )
  }

  renderDepositDescription() {
    if ( this.state.sales_order_item.deposit_average == 0 ) { return }

    let message
    if ( this.state.sales_order_item.deposit_average > 0 ) {
      message = 'This item has a refundable deposit'
    } else {
      message = 'Refundable deposit'
    }

    return (
      <div className='text-secondary small'>
        <span className='fa fa-recycle mr-1'></span>
        { message }
      </div>
    )
  }

  renderProducerName() {
    if (!this.props.showProducerName) { return }

    const product_unit = this.props.sales_order_item.product_unit
    return (
      <div className='text-secondary small'>{ product_unit.producer_name }</div>
    )
  }

  renderForecast() {
    if (this.props.showForecast) {
      return (
        <Forecast forecast={this.props.forecast}
                  order_item_id={this.props.sales_order_item.id}
                  product_unit={this.props.sales_order_item.product_unit}></Forecast>
      )
    }
  }

  renderBackorder() {
    if (this.props.sales_order_item.src == 'checkout_item') {
      return (
        <div>
          <span className="badge badge-info">Checkout Alternative</span>
        </div>
      )
    }
  }
  renderSrc() {
    if (this.props.sales_order_item.src) {
      const color = this.props.sales_order_item.quantity == 0 ? 'badge-success' : 'badge-info'
      return (
        <div>
          <span className={`badge ${ color}`}>Customer Backorder</span>
        </div>
      )
    }
  }

  renderUnavailable() {
    const product_unit = this.props.sales_order_item.product_unit

    if (product_unit.unavailable_until) {
      var color = product_unit.unavailable_type  == 'preorder' ? 'badge-success' : 'badge-warning'
      return (
        <div>
          <span className={`badge badge-pill ${ color }`}>
            <span className='fa fa-clock mr-1'></span>
           { formatDate(product_unit.unavailable_until) }: {product_unit.unavailable_until_description }
          </span>
        </div>
      )
    } else if  (!product_unit.is_published)  {
      return (
        <div>
          <span className='badge badge-pill badge-danger'>
            <span className='fa fa-exclamation-triangle mr-1'></span>
            Currently Unavailable
          </span>
        </div>
      )
    }
  }

  render() {
    const sales_order_item = this.props.sales_order_item
    const product_unit = sales_order_item.product_unit
    const buttonClass = this.state.showAdvanced ? 'btn-dark' : 'btn-outline-secondary'

    const status = this.state.status
    const rowClass = classNames({
      "order-row": true,
      "py-2": true,
      "save-failed":  status === "failed",
      "save-pending":  status === "sending",
      "save-success":  status === "success"
    })

    let minimum
    if (product_unit.quantity_minimum > 1) {
      minimum = <span className="badge border ml-2">{`${product_unit.quantity_minimum} minimum`}</span>
    }
    let split
    if (product_unit.split_multiplier) {
      split = <span className='fa fa-cut mx-1'></span>
    }

    let orderProductUnitCount
    if (this.props.orderProductUnitCount > 1) {
      const id = `order-multiple-${sales_order_item.id}`
      orderProductUnitCount = (
        <span>
          <span id={ id } className="badge badge-warning ml-2">{ this.props.orderProductUnitCount }</span>
          <UncontrolledTooltip placement="top" target={ id } >
            This product unit is on { this.props.orderProductUnitCount } line items in this order
          </UncontrolledTooltip>
        </span>
      )
    }

    return (
      <div className={rowClass}>
        <div className='toggle-more-column'>
          <button className={`h-100 btn btn-sm ${buttonClass}`} onClick={ this.toggleAdvanced }>
            <span className="fa fa-ellipsis-v"></span>
          </button>
        </div>
        <div className='quantity-column'>
          <div className='w-100'>
            { this.renderQuantity() }
          </div>
          { this.renderDeleteButton() }
        </div>
        <div className='item-column'>
          <div className='pointered d-flex' onClick={this.toggleAdvanced} >
            <div className='pr-2 d-none d-md-block'>
              <img src={product_unit.product_photo.small_url} height="60" />
            </div>
            <div>
              { this.renderProducerName() }
              <div className='font-weight-bold'>{ product_unit.product_name }</div>
              <div>{ product_unit.unit }{minimum}{orderProductUnitCount}{split}</div>
              { this.renderCatchWeightDescription() }
              { this.renderDepositDescription() }
              { this.renderBackorder() }
              { this.renderUnavailable() }
              { this.renderSellerNote() }
              { this.renderBuyerNote() }
              { this.renderInternalSellerNote() }

              { this.renderForecast() }
            </div>
          </div>
          { this.renderAdvancedEdit() }
        </div>
        <div>
          <div className='d-flex no-gutters'>
            <div className='unit-price-column'>
              { this.renderUnitPrice() }
              { this.renderOriginalEffectivePrice() }
              { this.renderDeposit() }
            </div>
            <div className='subtotal-price-column'>
              { this.renderSubtotal() }
              { this.renderOriginalSubtotal() }
              { this.renderDepositSubtotal() }
            </div>
          </div>
          { this.renderSubtotalOverride() }
          { this.renderDepositOverride() }
        </div>
      </div>
    )
  }
}
export default SalesOrderItemEdit